import KTApp from "./app";
import KTDrawer from "./drawer";
import KTMenu from "./menu";
import KTScroll from "./scroll";
import KTSticky from "./sticky";
import KTScrolltop from "./scrolltop";

// Init components
var KTComponents = (function () {
  // Public methods
  return {
    init: function () {
      KTApp.init();
      KTDrawer.init();
      KTMenu.init();
      KTScroll.init();
      KTSticky.init();
      // KTSwapper.init();
      // KTToggle.init();
      KTScrolltop.init();
      // KTDialer.init();
      // KTImageInput.init();
      // KTPasswordMeter.init();
    },
  };
})();


document.addEventListener("turbo:load", function () {
  KTComponents.init();
});

// Declare KTApp for Webpack support
if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
  window.KTComponents = module.exports = KTComponents;
}
